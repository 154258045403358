import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

import Header from './Header';
import ErrorBoundary from '../../ErrorBoundary';
import {
  useLocation
} from "react-router-dom";

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
        padding: 0 0px;
`
);

const WideLayout = () => {
  let location = useLocation();
  return (
    <>
      <MainWrapper>
        <Header />
        <MainContent>
          <ErrorBoundary key={location.pathname}>
            <Outlet />
          </ErrorBoundary>
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default WideLayout;
