import React from 'react';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Link from '@mui/material/Link';

class ErrorBoundary extends React.Component {

    

    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      //logErrorToMyService(error, errorInfo);
    }
  
    render() {
    
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return (
            
            <Container maxWidth="sm">
            <Box sx={{ mx: 5 }}>
            <Grid
              container
              justifyContent="center"
              alignItems="stretch"
              spacing={1}
              sx={{ marginTop: 5 }}
            >
              <Box height={250}>
                <Box component="form" noValidate>
                  
                    <Alert severity={"error"}>
                      Something went wrong.
                    </Alert>
        
                  <Box textAlign="center">
                    <Card>
                      <CardHeader 
                        title="Help us fix this." 
                        subheader="Please report on the link below"
                    />
                      <Divider />
                      <CardContent>
                        
                      <Link href="/contact" variant="body2">
                            Contact form
                        </Link>
                        
                      </CardContent>
                    </Card>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box>
            </Container>
        )
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary;