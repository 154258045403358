import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import Button from '@mui/material/Button';


/**
 * Renders a button which, when selected, will open a popup for login
 */
 export const SignInButton = () => {
    const { instance  } = useMsal();

     function handleLogin(loginType) {
        if (loginType === "popup") {
             instance.loginPopup(loginRequest)
            .catch(e => {
                console.log(e);
            });
        }

       
    }
    return (
        <Button variant="outlined" sx={{ my: 1, mx: 1.5 }} onClick={() => handleLogin("popup")}>Sign in</Button>
    );
}